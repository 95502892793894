export * from './lightbox'
export * from './cookies'
export * from './uuid'
export * from './promotion-countdown'

export function removeQueryParamUtility(param) {
    if (!param) {
        console.warn('No param provided');
        return;
    }

    if (typeof window !== 'undefined' && window?.location?.search) {
        const url = new URL(window.location);

        if (url.searchParams.has(param)) {
            url.searchParams.delete(param); 
            window.history.pushState({}, '', url); // Update the URL without reloading the page
        } else {
            console.warn(`Param ${param} not found in the query string.`);
        }
    }
}