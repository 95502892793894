<template>
  <!-- Render a button or an a tags based on href -->
  <component
    :is="componentType"
    :href="href ? href : null"
    :class="['ui-button',
      buttonType,
      appearance,
      size,
      {
        loading,
        disabled: isDisabled || currentLoading,
        block,
        inline
      },
      { 'horizontal-shake': animate === 'horizontal-shake' }
    ]"
    :disabled="isDisabled"
    :aria-label="ariaLabel || label"
    :aria-disabled="isDisabled"
    :type="type"
    @click="handleClick"
    @keydown.enter="handleClick"
    @keydown.space.prevent="handleClick"
    @animationend="handleAnimationEnd"
  >
    <!-- Icon before the label -->
    <slot name="icon-left">
      <span v-if="icon && iconPosition === 'left'" :class="['icon', icon]"></span>
    </slot>

    <!-- Loader or label/slot content -->
    <span v-if="loading" class="loader" :class="loadingClass"></span>
    <span v-else class="ui-button__label" :class="labelClass">
      <slot>{{ label }}</slot>
    </span>

    <!-- Icon after the label -->
    <slot name="icon-right" class="icon-slot">
      <span v-if="icon && iconPosition === 'right'" :class="['icon', icon]"></span>
    </slot>
  </component>
</template>

<script>
export default {
  props: {
    href: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: '', // Default empty to support slot usage
    },
    size: {
      type: String,
      default: 'normal',
      validator: value => ['normal', 'small'].includes(value),
    },
    buttonType: {
      type: String,
      default: 'fill',
      validator: value => ['fill', 'outline', 'blank'].includes(value),
    },
    appearance: {
      type: String,
      default: 'primary',
      validator: value => ['primary', 'secondary', 'tertiary', 'danger', 'delete'].includes(value),
    },
    uppercase: {
      type: Boolean,
      default: false
    },
    block: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    fontWeight: {
      type: String,
      default: 'normal',
      validator: value => ['normal', 'light', 'medium', 'semibold', 'bold'].includes(value),
    },
    action: {
      type: String,
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    ariaLabel: {
      type: String,
      default: null, // Optional aria-label for accessibility
    },
    type: {
      type: String,
      default: 'button',
      validator: value => ['button', 'submit', 'reset'].includes(value),
    },
    icon: {
      type: String,
      default: null,
    },
    iconPosition: {
      type: String,
      default: 'left',
      validator: value => ['left', 'right'].includes(value),
    },
    animate: {
      type: String,
      validator: value => [null, 'horizontal-shake'].includes(value),
    }
  },
  data() {
    return {
      currentLoading: false,
      isAnimating: false,
    };
  },
  computed: {
    componentType() {
      return this.href ? 'a' : 'button';
    },
    labelClass() {
      return {
        'ui-button__label--light': this.fontWeight === 'light',
        'ui-button__label--normal': this.fontWeight === 'normal',
        'ui-button__label--medium': this.fontWeight === 'medium',
        'ui-button__label--semibold': this.fontWeight === 'semibold',
        'ui-button__label--bold': this.fontWeight === 'bold',
        'ui-button__label--uppercase': this.uppercase,
      }
    },
    loadingClass() {
      return {
        'loading--primary': this.appearance === 'primary',
        'loading--secondary': this.appearance === 'secondary',
        'loading--tertiary': this.appearance === 'tertiary',
      }
    },
    isSubmit() {
      return this.type === 'submit';
    },
    isDisabled() {
      if(this.href) {
        return this.currentLoading || this.disabled;
      }

      return this.disabled;
    }
  },
  methods: {
    handleClick(e) {
      // Prevent default if no href and not a submit button
      if (!this.href && !this.isSubmit) {
        e.preventDefault();
      }

      // Set loading state only if there's a link or submission
      // This disable button/link after click
      if (this.href || this.isSubmit) {
        this.currentLoading = true;
      }

      // Emit click only if not loading or disabled
      if (!this.loading && !this.isDisabled) {
        this.$emit("click", e);
      }
    },
    handleAnimationEnd(event) {
      if (event.animationName === 'horizontal-shaking') {
        this.isAnimating = false;
        this.$emit('update:animateButton', false);
      }
    },

  },
  watch: {
    animateButton(newVal) {
      if (newVal) {
        this.isAnimating = true;
      }
    },
  },
};
</script>

<style>
a.ui-button {
  font-weight: inherit;
  display: inline-flex;
}
a.ui-button.block {
  display: flex;
}
a.ui-button.block span.ui-button__label {
  text-align: center;
  justify-content: center;
}

.ui-button {
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  border: 1px solid;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 12px 16px;
}
.ui-button .loader {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  animation: spin 1s linear infinite;
}

.ui-button .icon {
  display: flex;
  align-items: center;
}
.ui-button.loading {
  cursor: wait;
  pointer-events: none;
}
.ui-button.block {
  width: 100%;
  text-align: center;
}
.ui-button.inline {
  display: inline-flex;
}
.ui-button.disabled {
  cursor: not-allowed;
  opacity: 0.6;
  pointer-events: none;
}

/* Label */
.ui-button__label {
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  display: flex;
  gap: 10px;
  align-items: center;
}
.ui-button__label--light {
  font-weight: light;
}
.ui-button__label--normal {
  font-weight: normal;
}
.ui-button__label--medium {
  font-weight: medium;
}
.ui-button__label--semibold {
  font-weight: 600;
}
.ui-button__label--bold {
  font-weight: bold;
}
.ui-button__label--uppercase {
  text-transform: uppercase;
}

/* Small */
.ui-button.small {
  padding: 7px 10px;
}
.ui-button.small .ui-button__label {
  font-size: 12px;
}

/* Primary */
a.ui-button.primary {
  color: var(--color-white) !important;
}
.ui-button.primary {
  border-radius: 12px;
  background: var(--color-primary);
  color: var(--color-white);
}
.ui-button.primary.outline {
  background: var(--color-white);
  color: var(--color-primary);
  border-color: var(--color-primary);
}
a.ui-button.primary.outline {
  background: var(--color-white) !important;
  color: var(--color-primary) !important;
  border-color: var(--color-primary) !important;
}
.ui-button.primary.blank {
  background-color: transparent;
  color: var(--color-primary);
  border-color: transparent;
}
.ui-button .loader.loading--primary {
  border-top: 2px solid var(--color-primary);
  ;
}

/* Secondary */
a.ui-button.secondary {
  color: var(--color-white) !important;
}
.ui-button.secondary {
  border-radius: 12px;
  background: var(--color-secondary);
  color: var(--color-white);
}
.ui-button.secondary.outline {
  background-color: var(--color-white);
  color: var(--color-secondary) !important;
  border-color: var(--color-secondary);
}
a.ui-button.secondary.outline {
  background-color: var(--color-white) !important;
  color: var(--color-secondary) !important;
  border-color: var(--color-secondary) !important;
}
.ui-button.secondary.blank {
  background-color: transparent;
  color: var(--color-secondary) !important;
  border-color: transparent;
}
.ui-button .loader.loading--secondary {
  border-top: 2px solid var(--color-secondary);
}

/* Tertiary */
a.ui-button.tertiary {
  color: var(--color-dark) !important;
}
.ui-button.tertiary {
  border-radius: 12px;
  background: var(--color-tertiary);
  color: var(--color-dark);
}
.ui-button.tertiary.outline {
  background-color: transparent;
  color: var(--color-dark);
  border-color: var(--color-tertiary);
}
a.ui-button.tertiary.outline {
  background-color: transparent !important;
  color: var(--color-dark) !important;
  border-color: var(--color-tertiary) !important;
}
.ui-button.tertiary.blank {
  background-color: transparent;
  color: var(--color-tertiary);
  border-color: transparent;
}
.ui-button .loader.loading--tertiary {
  border-top: 2px solid var(--color-tertiary);
}

/* Danger */
a.ui-button.danger {
  color: var(--color-danger) !important;
}
.ui-button.danger {
  border-radius: 12px;
  background: var(--background-danger);
  border-color:  var(--background-danger);
  color: var(--color-danger);
}
.ui-button.danger.outline {
  background-color: transparent;
  color: var(--color-danger);
  border-color: var(--color-danger);
}
a.ui-button.danger.outline {
  background-color: transparent !important;
  color: var(--color-danger) !important;
  border-color: var(--color-danger) !important;
}
.ui-button.danger.blank {
  background-color: transparent;
  color: var(--color-danger);
  border-color: transparent;
}
.ui-button .loader.loading--danger {
  border-top: 2px solid var(--color-danger);
}

/* Delete */
a.ui-button.delete {
  color: var(--color-delete) !important;
}
.ui-button.delete {
  border-radius: 12px;
  background: var(--background-danger);
  border-color:  var(--background-danger);
  color: var(--color-delete);
}
.ui-button.delete.outline {
  background-color: transparent;
  color: var(--color-delete);
  border-color: var(--color-delete);
}
a.ui-button.delete.outline {
  background-color: transparent !important;
  color: var(--color-delete) !important;
  border-color: var(--color-delete) !important;
}
.ui-button.delete.blank {
  background-color: transparent;
  color: var(--color-delete);
  border-color: transparent;
}
.ui-button .loader.loading--delete {
  border-top: 2px solid var(--color-delete);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes horizontal-shaking {
 0% { transform: translateX(0) }
 25% { transform: translateX(3px) }
 50% { transform: translateX(-3px) }
 75% { transform: translateX(3px) }
 100% { transform: translateX(0) }
}

.ui-button.horizontal-shake {
  animation: horizontal-shaking .4s infinite;
  animation-iteration-count: 2;
}

.ui-button svg {
  flex-shrink: 0;
}
</style>
